import React, { useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom"
import { useColorModeValue as mode, Box, Flex, Center, Heading, Text } from "@chakra-ui/react"
import PricingCard from './PricingCard'
import { withTranslation, WithTranslation } from 'react-i18next'
import type { FCC } from 'types/utils'

const Pricing: FCC<WithTranslation> = ({t}) => {
  const { hash } = useLocation()
  const element = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hash && hash==='#pricing' && element.current) {
      element.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [hash, element])

  return (
    <Center>
      <Box
        width="100%"
        maxWidth="960px"
        paddingY="80px"
        id="pricing"
        ref={element}
      >
        <Heading
          as="h1"
          paddingX="16px"
          textAlign="center"
          fontSize={['24px', '32px', '40px']}
          fontWeight="bold"
        >{ t('pricing') }</Heading>
        <Text
          textAlign="center"
          mb="48px"
          paddingX="16px"
          fontSize="20px"
          color={mode('black.66', 'white.66')}
        >{ t('pricing description') }</Text>
        <Flex
          alignItems="center"
          overflow="auto"
          width="100%"
          padding="16px"
          gap="24px"
        >
          <PricingCard
            isFree
            title={t('free trial')}
            price={t('free price')}
            period={t('month')}
            buttonText={t('try for free')}
          />
          <PricingCard
            isHero
            title={t('yearly')}
            price={t('yearly price')}
            period={t('year')}
            priceSuffix={t('yearly price extra')}
            buttonText={t('buy now')}
          />
          <PricingCard
            title={t('monthly')}
            price={t('monthly price')}
            period={t('month')}
            buttonText={t('buy now')}
          />
        </Flex>
      </Box>
    </Center>
  )
}

export default withTranslation('toolbox')(Pricing);