import React from "react"
import { motion } from "framer-motion"
import { Loader } from 'react-feather'

interface Props {
  size?: string|number;
  [key: string]: any;
}

const Loading = function ({ size="20px", ...props}: Props) {
  const MotionBox = motion(Loader)
  return (
    <MotionBox
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity }}
      size={size}
      {...props}
    />
  )
}

export default Loading
