import React, { FC, PropsWithChildren } from "react";
import { useColorModeValue as mode, Flex, Box } from "@chakra-ui/react";

const AuthContainer: FC<PropsWithChildren> = function ({ children }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flex="1"
      p="64px"
      pt="136px"
      bg={mode('gradient.linearBGLight', 'gradient.linearBGDark')}
    >
      <Box p="32px" w="100%" maxW="360px" bg={mode('white', 'gray.700')} borderRadius="8px" boxShadow="sm">
        { children }
      </Box>
    </Flex>
  )
}

export default AuthContainer
