import React from "react"
import Inter from "./Inter"
import Poppins from "./Poppins"

const Fonts = () => {
  return (
    <>
      <Inter/>
      <Poppins/>
    </>
  )
}

export default Fonts
