import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link, Box, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import CenteredBox from 'components/common/CenteredBox'
import { Twitter } from 'react-feather'
import Logo from 'assets/Logo'

const Footer: FC<WithTranslation> = ({i18n, t}) => {
  return (
    <Box
      as="footer"
      bg={mode('blue.50', '#0a0b0d')}
      overflow="hidden"
    >
      <CenteredBox
        as={Flex}
        p="48px 16px"
        flexDirection={['column', 'row']}
        alignItems={['flex-start', 'flex-end']}
        justifyContent="space-between"
      >
        <Box mb={['24px', '0']}>
          <Link as={RouterLink} to="/" color="primary">
            <Logo/>
          </Link>
          <Text>Copyright © {(new Date()).getFullYear()}, Heron design</Text>
        </Box>
        <Flex justifyContent="center" alignItems="center" gap="20px">
          <Link href="https://twitter.com/hal_lee_at_x" target="_blank">
            <Twitter size={16}/>
          </Link>
          <Link href="https://blog.heronhq.com" target="_blank">{t('blog')}</Link>
          <Link href="https://docs.heronhq.com" target="_blank">{t('docs')}</Link>
        </Flex>
      </CenteredBox>
    </Box>
  )
}

export default withTranslation('common')(Footer)
