import { CheckCircle, Info, AlertTriangle, XCircle } from 'react-feather'

export const feedbackMaps = {
    'success': {
      icon: CheckCircle,
      iconColor: 'green.500',
      textColor: 'green.900',
      backgroundColor: 'green.50'
    },
    'warning':  {
      icon: AlertTriangle,
      iconColor: 'orange.500',
      textColor: 'orange.900',
      backgroundColor: 'orange.50'
    },
    'error': {
      icon: XCircle,
      iconColor: 'red.500',
      textColor: 'red.900',
      backgroundColor: 'red.50'
    },
    'info':  {
      icon: Info,
      iconColor: 'blue.500',
      textColor: 'blue.900',
      backgroundColor: 'blue.50'
    },
    'default': {
      icon: Info,
      iconColor: 'gray.500',
      textColor: 'gray.900',
      backgroundColor: 'gray.50'
    }
}
