import React from 'react'
import { useColorModeValue as mode, Box, Image, Center, Heading, Text, SimpleGrid } from "@chakra-ui/react"
import { withTranslation, WithTranslation } from 'react-i18next';
import type { FCC } from 'types/utils';

const Features: FCC<WithTranslation> = ({t}) => {
  return (
    <Center>
      <Box
        width="100%"
        maxWidth="800px"
        paddingBottom="64px"
      >
        <Heading as="h1" textAlign="center" fontSize={['20px', '24px', '28px']} fontWeight="500" mb="64px">{t('usages title')}</Heading>
        <SimpleGrid columns={[1, 2, 3]} gap="24px">
          <Box textAlign="center">
            <Image src="/transfer/fill-content.svg" width="80px" marginX="auto" marginBottom="24px"/>
            <Text as="p" fontSize="20px" fontWeight="medium" color={mode('black.86', 'white.86')}>{t('fill design')}</Text>
          </Box>
          <Box textAlign="center">
            <Image src="/transfer/collect-ui.svg" width="80px" marginX="auto" marginBottom="24px"/>
            <Text as="p" fontSize="20px" fontWeight="medium" color={mode('black.86', 'white.86')}>{t('collect ui')}</Text>
          </Box>
          <Box textAlign="center">
            <Image src="/transfer/compare-dev.svg" width="80px" marginX="auto" marginBottom="24px"/>
            <Text as="p" fontSize="20px" fontWeight="medium" color={mode('black.86', 'white.86')}>{t('compare with dev')}</Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Center>
  )
}

export default withTranslation('toolbox')(Features);