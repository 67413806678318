import React, { useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom"
import { Box, Flex, Center, Heading } from "@chakra-ui/react"
import PricingCard from './PricingCard'
import { withTranslation } from 'react-i18next'

import type { WithTranslation } from 'react-i18next'
import type { FCC } from 'types/utils'

const Pricing: FCC<WithTranslation> = ({t}) => {
  const { hash } = useLocation()
  const element = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hash && hash==='#pricing' && element.current) {
      element.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [hash, element])

  return (
    <Center>
      <Box
        width="100%"
        maxWidth="960px"
        paddingY="80px"
        id="pricing"
        ref={element}
      >
        <Heading
          as="h1"
          marginBottom="64px"
          paddingX="16px"
          textAlign="center"
          fontSize={['24px', '32px', '40px']}
          fontWeight="bold"
        >{ t('pricing') }</Heading>
        <Flex
          alignItems="center"
          justifyContent={["flex-start", "flex-start", "center"]}
          overflow="auto"
          width="100%"
          padding="32px"
          gap="24px"
        >
          <PricingCard
            title={t('trial')}
            price={t('trial price')}
            priceExplanation={t('trial explanation')}
            buttonText={t('try for free')}
            link="https://www.figma.com/community/plugin/1151162728819593106"
          />
          <PricingCard
            isHero
            title={t('lifetime')}
            price={t('lifetime price')}
            priceExplanation={t('lifetime explanation')}
            buttonText={t('buy now')}
            link={t('purchase link')}
          />
        </Flex>
      </Box>
    </Center>
  )
}

export default withTranslation('themeTools')(Pricing)
