import React from 'react'
import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/react"
import { withTranslation } from 'react-i18next'
import FormatItem from './FormatItem'
import Svg from 'components/icons/Svg'
import ReactLogo from 'components/icons/ReactLogo'
import Vue from 'components/icons/Vue'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const Features: FCC<WithTranslation> = ({t}) => {
  return (
    <Center>
      <Box
        width="100%"
        maxWidth="800px"
        paddingBottom="64px"
      >
        <Heading as="h1" textAlign="center" fontSize={['24px', '40px']} fontWeight="bold" mb="64px">{t('formats title')}</Heading>
        <SimpleGrid columns={[1, 2, 3]} gap="24px">
          <FormatItem
            icon={Svg}
            format="SVG"
          />
          <FormatItem
            icon={ReactLogo}
            format="React"
          />
          <FormatItem
            icon={Vue}
            format="Vue"
          />
        </SimpleGrid>
      </Box>
    </Center>
  )
}

export default withTranslation('iconBaker')(Features)
