const Button = {
  baseStyle: {
    borderRadius: 'lg',
    fontWeight: 'medium'
  },
  defaultProps: {
    size: 'sm',
    colorScheme: 'blue'
  }
}

export default Button
