import increment from 'api/increment'

interface State {
  loading: boolean;
  count: number;
}

interface Action {
  payload: number;
}

interface Payload {
  data: number;
}

const model = {
  // model名称，view层用于提取state的key，需要保证唯一
  name: 'increment',
  // 初始state状态
  state: {
    loading: false,
    count: 0,
  },
  // reducer
  reducers: {
    'INCREMENT': function(state: State, action: Action) {
      return { ...state, loading: true }
    },
    'INCREMENT_START': function(state: State, action: Action) {
      return { ...state, loading: true }
    },
    'INCREMENT_ASYNC_SUCCESS': function(state: State, action: Action) {
      const { payload } = action
      return { ...state, loading: false, count: state.count + payload }
    },
  },
  // saga
  effects: {
    'INCREMENT_ASYNC': function*({ payload }: {payload: Payload}, { call, put }: any) {
      yield put({ type: 'INCREMENT_START'})
      const resp: Payload  = yield call(increment, payload)
      if (resp) {
        console.log("resp", resp)
        yield put({ type: 'INCREMENT_ASYNC_SUCCESS', payload: resp.data })
      } else {
        throw resp
      }
    },
  },
}

export default model
