import React from 'react'
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { FeatureIcon, FeatureType } from './FeaturesOverview';

interface Props {
  featureType: FeatureType
  title: string;
  description: string;
}

function OverviewItem ({ featureType, title, description }: Props) {
  return (
    <Flex alignItems="flex-start" gap="12px">
      <Box
        marginTop="4px"
        padding="12px"
        color="primary"
        backgroundImage="url('/variable-tools/icon-bg.svg')"
      >
        <FeatureIcon featureType={featureType}/>
      </Box>
      <Box>
        <Heading as="h3" fontSize="20px" fontWeight="500" mb="4px">{ title }</Heading>
        <Text mb="4px" fontSize="16px" color="text.tertiary">{ description }</Text>
      </Box>
    </Flex>
  )
}

export default OverviewItem
