import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const PublicLayout = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header/>
      <Outlet/>
      <Footer/>
    </Box>
  );
}

export default PublicLayout
