import supabase from "utils/supabase"

import type { User } from "types/user"

export interface UserState {
  info: User;
}

interface Action {
  payload: User;
}

const user = supabase.auth.user()

const model = {
  // model名称，view层用于提取state的key，需要保证唯一
  name: 'user',
  // 初始state状态
  state: {
    info: user,
  },
  // reducer
  reducers: {
    'UPDATE_USER': function(state: UserState, action: Action) {
      return { ...state, info: action.payload }
    },
    'CLEAR_USER': function(state: UserState) {
      return { ...state, info: null }
    }
  },
}

export default model
