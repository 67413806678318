import { Flex, Box, Icon } from "@chakra-ui/react"
import React from "react"
import { feedbackMaps } from "assets/theme/maps"

import type { As } from '@chakra-ui/react'
import type { FCC, Status } from "types/utils"

interface Props {
  status?: Status;
  icon?: As;
}

const Alert:FCC<Props> = function ({status='default', icon, children}) {
  const { icon: builtinIcon, iconColor, textColor, backgroundColor } = feedbackMaps[status]
  return (
    <Flex
      alignItems="center"
      padding="12px 16px"
      color={textColor}
      background={backgroundColor}
      borderRadius="8px"
    >
      <Icon as={!!icon ? icon : builtinIcon} width="20px" height="20px" marginRight="12px" color={iconColor}/>
      <Box fontWeight="400">
        { children }
      </Box>
    </Flex>
  )
}

export default Alert
