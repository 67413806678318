const textarea = {
  defaultProps: {
    size: 'sm'
  },
  sizes: {
    sm: {
      borderRadius: 'lg'
    }
  }
}

export default textarea
