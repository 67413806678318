import React from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from 'react-router-dom'

import type { FCC } from "types/utils"

// 登录才可以访问
export const AuthProtector: FCC = ({children}) => {
  const userInfo = useSelector((state: any) => state.user.info)
  const location = useLocation()
  const { pathname, search } = location
  const backUrl = encodeURIComponent(pathname+search)

  return !!userInfo ?
    <>{ children }</> :
    <Navigate to={`/login?backUrl=${backUrl}`} replace={true}/>
}

interface AntiAuthProps {
  destination?: string;
}

// 已登录不可访问
export const AntiAuthProtector: FCC<AntiAuthProps> = ({children}) => {
  const userInfo = useSelector((state: any) => state.user.info)

  return !!userInfo ?
    <Navigate to={'/dash'} replace={true}/> :
    <>{ children }</>
}
