import React, { ChangeEvent } from 'react';
import { useColorModeValue as mode, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { Globe } from 'react-feather'
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { languages } from 'utils/constants';

import type { FCC } from 'types/utils';
import type { Language } from 'types/common';

interface LanguageState {
  language: Language
}

const LanguageSwitcher:FCC<WithTranslation> = function ({i18n, t}) {
  const { language } = useSelector(({common}: {common: LanguageState}) => common)
  const dispatch = useDispatch()

  function changeLanguage(e: ChangeEvent<HTMLSelectElement>) {
    const { value } = e.target
    i18n.changeLanguage(value)
    localStorage.setItem('heron.language', value)
    dispatch({
      type: 'COMMON/CHANGE_LANGUAGE',
      payload: value
    })
  }

  return (
    <Tooltip label={t('change language')} aria-label={t('change language')}>
      <Flex
        as="label"
        position="relative"
        padding="4px"
        color={mode('black.66', 'white.66')}
        sx={{
          select: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "28px",
            opacity: 0,
            cursor: "pointer"
          }
        }}
      >
        <Icon as={Globe} width="20px" height="20px" mr="4px" color={mode('gray.600', 'gray.400')}/>
        <Text
          display={['none', 'block']}
          lineHeight="20px"
        >{ languages[language] }</Text>
        <select
          onChange={changeLanguage}
          value={language}
        >
          {
            Object.keys(languages).map((key) =>
              <option value={key} key={key}>{languages[key as Language]}</option>
            )
          }
        </select>
      </Flex>
    </Tooltip>
  )
}

export default withTranslation('common')(LanguageSwitcher)
