import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_BASE || '',
  process.env.REACT_APP_ANON_KEY || ''
)

export function getImageUrl (bucket: string, folder: string, url?: string) {
  const { publicURL, error } = supabase
    .storage
    .from(bucket)
    .getPublicUrl([folder, url].filter(u => !!u).join('/'))
  if (publicURL) {
    return publicURL
  } else {
    throw(error)
  }
}

export default supabase
