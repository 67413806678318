import type { IconLibrary, SelectedIcon } from "types/icon"

export interface IconState {
  profile: any;
  library: IconLibrary;
  size: number;
  icons: any[];
  selectedIcons: SelectedIcon[];
}

interface Action {
  payload: any;
}

const model = {
  // model名称，view层用于提取state的key，需要保证唯一
  name: 'icon',
  // 初始state状态
  state: {
    profile: null,
    library: null,
    size: 24,
    icons: [],
    selectedIcons: []
  },
  // reducer
  reducers: {
    // 更新 profile 信息
    'CHANGE_PROFILE': function(state: IconState, action: Action) {
      return { ...state, profile: action.payload }
    },
    // 更新图标库信息
    'CHANGE_ICON_LIBRARY': function(state: IconState, action: Action) {
      return { ...state, library: action.payload }
    },
    // 初始化图标
    'INIT_ICONS': function(state: IconState, action: Action) {
      // TODO: 异步函数移进 effects 
      const icons = action.payload
      return { ...state, icons }
    },
    // 更新图标代码
    'CHANGE_ICON_CODE': function(state: IconState, action: Action) {
      const { id: iconId, format, code } = action.payload
      const updatedIcons = [...state.icons]
      const index = state.icons.findIndex(({id}) => iconId===id)
      updatedIcons.splice(index, 1, {...state.icons[index], [format]: code})
      return { ...state, icons: updatedIcons }
    },
    // 更新图标显示尺寸
    'CHANGE_SIZE': function(state: IconState, action: Action) {
      return { ...state, size: action.payload }
    },
    // 删除图标
    'DELETE_ICONS': function(state: IconState, action: Action) {
      const { icons } = state
      const selectedIcons: SelectedIcon[] = action.payload
      const deletingIds = selectedIcons.map(({id})=>id)
      const restIcons = icons.filter(({id}: any)=>!deletingIds.includes(id))
      return { ...state, icons: restIcons }
    },
    // 更新选中图标
    'CHANGE_SELECTED_ICONS': function(state: IconState, action: Action) {
      const selectedIcons = action.payload
      return { ...state, selectedIcons }
    },
  },
}

export default model
