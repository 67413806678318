import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

interface Reducer {
  [reducerName: string]: any
}

// 用于缓存所有reducer（即state）
const reducer: Reducer = {}
// 读取所有.model.js结尾的文件
const models = require.context('./models', true, /\.ts$/)

// @ts-ignore
models.keys().map(path => {// @ts-ignore
  // 引入model
  const item = models(path).default
  // console.dir(item)
  // 对每个model进行操作-处理对应的state和reducer
  if (item && item.name) {
    const { name, state={}, reducers={} } = item
    // 使用handleActions整合所有的reducer函数
    reducer[name] = handleActions(reducers, state)
  }
  return true
})

const rootReducer = combineReducers({
  ...reducer,
})

export default rootReducer