import React from 'react'
import { Box, Image, useColorMode } from '@chakra-ui/react'

function Plugin ({pos}: {pos: number}) {
  const { colorMode } = useColorMode()
  return (
    <Box
      position="absolute"
      top="15%"
      left="0"
      height="70%"
      backgroundColor="bg"
      borderRadius="8px"
      boxShadow="lg"
      zIndex={1}
    >
      <Image
        position="absolute"
        top={`${44.5-pos/12.5}%`}
        left="5%"
        width="90%"
        src={`/theme-tools/${colorMode}-themes.png`}/>
      <Image
        position="relative"
        height="100%"
        src={`/theme-tools/plugin-${colorMode}.png`}
        zIndex={1}
      />
    </Box>
  )
}

export default Plugin
