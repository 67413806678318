import React, { ReactNode } from "react";
import { AntiAuthProtector, AuthProtector } from "./AuthWrapper";

export function withAuthProtector (component: ReactNode) {
  return (
    <AuthProtector>
      { component }
    </AuthProtector>
  )
}

export function withAntiAuthProtector (component: ReactNode, destination?: string) {
  return (
    <AntiAuthProtector destination={destination}>
      { component }
    </AntiAuthProtector>
  )
}
