import styled from "@emotion/styled"
import React from "react"

const StyledSVG = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-80px) rotateX(-30deg);
`

function FigmaLogo ({
    strokeWidth=1,
    color='currentColor'
  }: {
    opacity?: number,
    strokeWidth?: number,
    color?: string
}) {
  return (
    <StyledSVG
      className="outline-vector"
      width="12%"
      height="12%"
      viewBox="0 0 42 62"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      style={{
        marginTop: "-6%",
        marginLeft: "-6%"
      }}
    >
      <path d="M21 1H11C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21M21 1V21M21 1H31C36.5228 1 41 5.47715 41 11C41 16.5228 36.5228 21 31 21M21 21H11M21 21V41M21 21H31M11 21C5.47715 21 1 25.4772 1 31C1 36.5228 5.47715 41 11 41M21 41H11M21 41V51C21 56.5228 16.5228 61 11 61C5.47715 61 1 56.5228 1 51C1 45.4772 5.47715 41 11 41M31 21C36.5228 21 41 25.4772 41 31C41 36.5228 36.5228 41 31 41C25.4772 41 21 36.5228 21 31C21 25.4772 25.4772 21 31 21Z" vectorEffect="non-scaling-stroke"/>
    </StyledSVG>
  )
}

export default FigmaLogo
