import { serverBase } from "utils/constants";
import supabase from "utils/supabase"

type AssociationData = {
  id: string;
  user_id: string;
}

export async function upsertAssociation (data: AssociationData) {
  return await supabase
    .from('id_associations')
    .insert([data])
}

export async function generateFigmaAuth (code: string, clientId: string) {
  return await fetch(
    `${serverBase}/user/generateFigmaAuth`,
    {
      method: 'POST',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        code,
        clientId
      })
    }
  )
  .then(res => res.json())
}
