import React from 'react'
import { Flex, Button, Heading, Text } from "@chakra-ui/react"

import type { FCC } from 'types/utils'

interface Props {
  isHero?: boolean;
  title: string;
  price: string;
  priceExplanation: string;
  buttonText: string;
  link: string;
}

const PricingCard:FCC<Props> = function ({ isHero, title, price, priceExplanation, buttonText, link }) {

  return (
    <Flex
      direction="column"
      width="256px"
      flexShrink={0}
      padding="32px"
      borderRadius="16px"
      color={isHero ? 'white' : 'black'}
      background={isHero ? 'gray.900' : 'white'}
      boxShadow="base"
      transition="0.2s all"
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)"
      }}
    >
      <Heading as="h3" marginBottom="8px" fontSize="16px" fontWeight="bold">{ title }</Heading>
      <Text marginBottom="16px" fontSize="14px" opacity={0.56}>{ priceExplanation }</Text>
      <Heading as="h1" fontSize="32px" fontWeight="bold" mb="32px">
        { price }
      </Heading>
      <Button
        as="a"
        size="lg"
        width="100%"
        variant={isHero ? 'solid' : 'outline'}
        href={link}
      >
        { buttonText }
      </Button>
    </Flex>
  )
}

export default PricingCard
