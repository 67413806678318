import React, { useRef } from 'react'
import { Heading, Text, Image, Box, Flex } from "@chakra-ui/react"
import { withTranslation } from 'react-i18next'
import { useGsap } from 'utils/hooks'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'
import Hexagon from './Hexagon'
import FigmaLogo from './FigmaLogo'
import CenteredBox from 'components/common/CenteredBox'
import Button from 'components/custom/Button'

const Hero:FCC<WithTranslation> = ({t}) => {
  const root = useRef(null)

  useGsap(root, gsap => {
    gsap.to('.figma-logo', {
      rotateX: 0,
      y: 0,
      scrollTrigger: {
        trigger: root.current,
        start: 'top top+=72',
        end: 'bottom center',
        scrub: 1,
      }
    })
    gsap.to('.outline-vector', {
      rotateX: 0,
      y: 0,
      scrollTrigger: {
        trigger: root.current,
        start: 'top top+=72',
        end: 'bottom center',
        scrub: 1,
      }
    })
  })

  return (
    <CenteredBox
      display="flex"
      flexDirection={['column', 'column', 'row', 'row', 'row']}
      alignItems="center"
      gap={['48px', '48px', '0', '0', '0']}
      paddingX="16px"
      paddingTop="80px"
      paddingBottom={["32px", "64px"]}
      ref={root}
    >
      <Box
        flex={1}
        width={['100%', '100%', 'auto', 'auto', 'auto']}
        textAlign={['center', 'center', 'left', 'left', 'left']}
      >
        <Heading
          as="h1"
          mb="8px"
          fontSize={['40px', '40px', '45px', '56px', '64px']}
          fontWeight={500}
          lineHeight={1.32}
        >
          {t("hero title 1")}
          <Text as="div" color="primary">{t("hero title 2")}</Text>
        </Heading>
        <Text mb="24px" fontSize={['14px', '16px', '16px', '20px']} color="text.secondary">
          {t('hero description')}
        </Text>
        <Flex display="inline-flex" gap="16px">
          <Button variant="outline" as="a" target="_blank" href="https://s.heronhq.com/vartools">{t('try button')}</Button>
          <Button as="a" target="_blank" href="https://s.heronhq.com/buy-hvtools">{t('buy button')}</Button>
        </Flex>
      </Box>
      <Box
        className='container'
        flexShrink={0}
        position="relative"
        width={['320px', '360px', '360px', '480px', '540px']}
        sx={{
          perspective: 1000
        }}
      >
        <Box paddingTop="100%" color="primary">
          <Image
            className="figma-logo"
            src="/variable-tools/blurred-figma.png"
            position="absolute"
            top="50%"
            left="50%"
            width="20%"
            height="20%"
            marginTop="-10%"
            marginLeft="-10%"
          />
          <FigmaLogo/>
          <Hexagon percentageSize={20} y={-80} opacity={0.70}/>
          <Hexagon percentageSize={40} y={-70} opacity={0.55}/>
          <Hexagon percentageSize={60} y={-60} opacity={0.40}/>
          <Hexagon percentageSize={80} y={-50} opacity={0.25}/>
          <Hexagon percentageSize={100} y={-40} opacity={0.10}/>
        </Box>
      </Box>
    </CenteredBox>
  )
}

export default withTranslation('variableTools')(Hero)
