import supabase from "./supabase"

export const getAuthHeaders = () => {
  const user = supabase.auth.user()
  const session = supabase.auth.session()
  return {
    'Authorization': session?.access_token||'',
    'X-User-Id': user?.id||''
  }
}
