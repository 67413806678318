import React from 'react'
import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/react"
import FeatureCard from 'components/common/FeatureCard'
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const Features: FCC<WithTranslation> = ({t}) => {
  return (
    <Center>
      <Box maxWidth="960px" paddingX="16px" paddingY="64px">
        <Heading as="h1" textAlign="center" fontSize={['24px', '32px', '40px']} fontWeight="bold" mb="80px">
          {t('meet all your needs')}
        </Heading>
        <SimpleGrid columns={[1, 2, 3]} gap="56px">
          <FeatureCard
            image="/icon-baker/feature-1.png"
            title={t('single source')}
            description={t('single source description')}
          />
          <FeatureCard
            image="/icon-baker/feature-2.png"
            title={t('multiple formats')}
            description={t('multiple formats description')}
          />
          <FeatureCard
            image="/icon-baker/feature-3.png"
            title={t('code hosting platform')}
            description={t('code hosting platform description')}
          />
          <FeatureCard
            image="/icon-baker/feature-4.png"
            title={t('easy to use')}
            description={t('easy to use description')}
          />
          <FeatureCard
            image="/icon-baker/feature-5.png"
            title={t('with typings')}
            description={t('with typings description')}
          />
          <FeatureCard
            image="/icon-baker/feature-6.png"
            title={t('follow-up automation')}
            description={t('follow-up automation description')}
          />
        </SimpleGrid>
      </Box>
    </Center>
  )
}

export default withTranslation('iconBaker')(Features)
