import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useColorModeValue as mode, Link, Box, Flex, Tooltip } from '@chakra-ui/react';
import { Twitter } from 'react-feather';
import { withTranslation, WithTranslation } from 'react-i18next';
import CenteredBox from 'components/common/CenteredBox';
import ThemeSwitcher from 'components/common/ThemeSwitcher'
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { FCC } from 'types/utils';
import Logo from 'assets/Logo';

const Header:FCC<WithTranslation> = ({ t }) => {
  return (
    <Box
      as="header"
      position="fixed"
      w="full"
      h="72px"
      zIndex="1000"
      backgroundColor={mode('whiteAlpha.800', 'rgb(26 29 33 / 80%)')}
      backdropFilter="saturate(180%) blur(20px)"
    >
      <CenteredBox as={Flex} p="16px" alignItems="center" justifyContent="space-between">
        <Box as="nav" display="flex" alignItems="center" gap="32px">
          <Link as={RouterLink} to="/" color="primary">
            <Logo/>
          </Link>
          <Link href="https://blog.heronhq.com" target="_blank">{t('blog')}</Link>
          <Link href="https://docs.heronhq.com" target="_blank">{t('docs')}</Link>
        </Box>
        <Flex gap="8px">
          <Tooltip label={t('follow me')} aria-label={t('follow me')}>
            <Box padding="4px" cursor="pointer" color={mode('gray.600', 'gray.400')}>
              <Link href="https://twitter.com/hal_lee_at_x" target="_blank">
                <Twitter size={20}/>
              </Link>
            </Box>
          </Tooltip>
          <ThemeSwitcher/>
          <LanguageSwitcher/>
        </Flex>
      </CenteredBox>
    </Box>
  );
}

export default withTranslation('common')(Header);
