import React, { MouseEventHandler } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Flex, Heading, Image, Icon, Text, useColorModeValue as mode, Button, Link } from "@chakra-ui/react"
import { ArrowRight, Figma } from 'react-feather'
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { As } from '@chakra-ui/react'
import type { WithTranslation } from 'react-i18next'

interface Props {
  logo: string;
  title: string;
  description: string;
  link?: string;
  installicon?: As;
  installText?: string;
  installLink?: string;
}

const Product:FCC<WithTranslation&Props> = function ({ logo, title, description, link, installicon, installText, installLink, t }) {
  const navivate = useNavigate()

  const jumpTo:MouseEventHandler<HTMLDivElement> = function  (e) {
    if ((e.target as HTMLElement).tagName!=='A' && link) {
      navivate(link)
    }
  }

  return (
    <Flex
      direction="column"
      borderRadius="16px"
      padding="24px"
      boxShadow="xs"
      backgroundColor={mode('white', 'gray.800')}
      cursor={link ? 'pointer' : 'default'}
      transition="all 0.2s"
      _hover={{
        boxShadow: link ? 'lg' : 'xs',
      }}
      onClick={jumpTo}
    >
      <Image src={logo} width="48px"/>
      <Box flex="1" marginY="56px">
        <Heading as="h3" fontSize="24px">{ title }</Heading>
        <Text>{ description }</Text>
      </Box>
      <Flex>
        {
          installLink &&
          <Button
            variant="outline"
            rounded="full"
            as={Link}
            href={installLink}
            target="_blank"
          >
            <Icon as={installicon||Figma} w="14px" h="14px" marginRight="4px"/>
            {installText||t('install')}
          </Button>
        }
        <Box flex={1}/>
        {
          link &&
          <Button
            variant="link"
            as={RouterLink}
            to={link}
          >
            {t('learn more')}
            <Icon as={ArrowRight} w="14px" h="14px" marginRight="4px"/>
          </Button>
        }
      </Flex>
    </Flex>
  )
}

export default withTranslation('home')(Product)
