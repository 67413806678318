const shadows = {
  'xs': '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
  'sm': '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
  'base': '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.12)',
  'md': '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.1)',
  'lg': '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.08)',
  'xl': '0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);',
  '2xl': '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 20px 32px 2px rgba(96, 97, 112, 0.12)',
  'slider': '0px 0px 1px rgba(40, 41, 61, 0.2), 0px 4px 8px rgba(96, 97, 112, 0.24)',
}

export default shadows
