import { Language } from "types/common";

const heronLanguage = localStorage.getItem('heron.language')

interface Modal {
  isOpen: boolean;
}

export interface CommonState {
  language: Language;
  modal: {
    [key: string]: Modal
  };
}

export const DELETE_ICONS_KEY = 'deleteIcons'

const model = {
  // model名称，view层用于提取state的key，需要保证唯一
  name: 'common',
  // 初始state状态
  state: {
    language: heronLanguage||'en-US',
    modal: {}
  },
  // reducer
  reducers: {
    'COMMON/CHANGE_LANGUAGE': function(state: CommonState, action: any) {
      return { ...state, language: action.payload }
    },
    'COMMON/TOGGLE_MODAL': function(state: CommonState, action: any) {
      const { key } = action.payload
      const cloned = {...state.modal}
      if (!cloned[key]) {
        cloned[key] = { isOpen: true }
      } else {
        cloned[key].isOpen = !cloned[key].isOpen
      }
      return { ...state, modal: cloned }
    }
  },
}

export default model
