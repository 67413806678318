import { RefObject, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { reduceSelectedIcon } from './icons'

import type { MouseEvent } from 'react'

gsap.registerPlugin(ScrollTrigger)

export function useProtector () {
  return function (e: MouseEvent<HTMLElement>) {
    const isMoreButton = (e.target as HTMLElement).classList.contains('more-actions-button')
    if (isMoreButton) {
      e.preventDefault()
    }
  }
}

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useSelectedIconAnchor (icons: any[]) {
  const dispatch = useDispatch()
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const iconId = searchParams.get('iconId')
  useEffect(() => {
    if (iconId) {
      const icon = icons.find(({id}) => id===iconId)
      if (icon) {
        dispatch({
          type: 'CHANGE_SELECTED_ICONS',
          payload: [reduceSelectedIcon(icon)]
        })
        const iconEl = document.getElementById(iconId)
        iconEl?.scrollIntoView()
        // 移除 search，避免二次跳动
        navigate(location.pathname)
      }
    }
  }, [iconId, icons, dispatch, location.pathname, navigate])
}

export function useGsap (root: RefObject<HTMLElement>, callback: (gsap: GSAP)=>void) {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      callback(gsap)
    }, root);
    return () => ctx.revert();
  // eslint-disable-next-line
  }, []);
}
