import { mode, Styles } from "@chakra-ui/theme-tools"

const styles: Styles = {
  global: (props) => ({
    color: mode("gray.800", "whiteAlpha.900")(props),
    bg: mode("white", "gray.800")(props),
    lineHeight: "base",
    html: {
      scrollBehavior: 'smooth',
    },
    "body": {
      fontSize: 14
    },
    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
    fontFeatureSettings: `"pnum"`,
    fontVariantNumeric: "proportional-nums",
    // 拖拽选择框样式
    '.ds-selector': {
      borderColor: 'blue.500!important',
      background: 'rgb(15 111 255 / 8%)!important'
    }
  }),
};

export default styles
