import React from 'react'
import { ButtonProps, LinkProps, chakra } from '@chakra-ui/react'

type Props = { children: React.ReactNode, variant?: 'solid' | 'outline'} & ButtonProps & LinkProps

function Button ({ children, variant='solid', ...props }: Props) {
  return (
    <chakra.button
      px='8'
      py='2'
      rounded='xl'
      border="1px solid"
      borderColor="primary"
      fontSize="18px"
      fontWeight="medium"
      textAlign="center"
      color={variant==='outline' ? 'primary' : "white"}
      bg={variant==='outline' ? 'transparent' : 'primary'}
      transition="all 0.24s"
      _hover={{
        borderColor: "primary.hover",
        bg: variant==='outline' ? 'rgba(15, 111, 255, 0.2)' : 'primary.hover'
      }}
      _active={{
        borderColor: "primary.pressed",
        bg: variant==='outline' ? 'rgba(15, 111, 255, 0.28)' : 'primary.pressed'
      }}
      {...props}
    >
      { children }
    </chakra.button>
  )
}

export default Button
