import React from 'react'
import { useColorModeValue as mode, Box } from '@chakra-ui/react'
import Hero from './components/Hero'
import Formats from './components/Formats'
import Features from './components/Features'
import GetEarlyAccess from './components/GetEarlyAccess'

const IconBakerPage = () => {

  return (
    <>
      <Box
        paddingTop="72px"
        background={mode('gradient.linearBGLight', 'gradient.linearBGDark')}
      >
        <Hero/>
        <Formats/>
        <Features/>
        <GetEarlyAccess/>
      </Box>
    </>
  )
}

export default IconBakerPage
