import React from 'react'
import { Flex, Button, Heading, Text, UnorderedList, ListItem, Icon } from "@chakra-ui/react"
import { Star } from 'react-feather';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FCC } from 'types/utils';

interface Props {
  isHero?: boolean;
  isFree?: boolean;
  title: string;
  price: string;
  period: string;
  priceSuffix?: string;
  buttonText: string;
}

const PricingCard:FCC<Props&WithTranslation> = function ({ isHero, isFree, title, price, period, priceSuffix, buttonText, t }) {

  return (
    <Flex
      direction="column"
      width={isHero ? '308px' : '272px'}
      height={isHero ? '464px' : '432px'}
      flexShrink={0}
      padding="32px"
      borderRadius="16px"
      color={isHero ? 'white' : 'black'}
      background={isHero ? 'gray.900' : 'white'}
      boxShadow="base"
      transition="0.2s all"
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)"
      }}
    >
      <Heading as="h3" marginBottom="8px" fontSize="16px" fontWeight="bold">{ title }</Heading>
      <Heading as="h1" fontSize="32px" fontWeight="bold" mb="32px">
        { price }
        <Text as="span" mr="8px" fontSize="14px">/{ period }</Text>
        <Text as="span" fontSize="14px" opacity={0.32}>{ priceSuffix }</Text>
      </Heading>
      <UnorderedList mb="32px" flex={1} lineHeight="24px">
        <ListItem
          fontWeight={isFree ? 400 : 600}
        >
          <Flex alignItems="center">
            {
              isFree ?
              t('trial storage') :
              t('paid storage')
            }
            {
              !isFree &&
              <Icon
                as={Star}
                ml="4px"
                color='common.yellow'
              />
            }
          </Flex>
        </ListItem>
        <ListItem>{t('pricing auto compress')}</ListItem>
        <ListItem>{t('pricing customize scale')}</ListItem>
        <ListItem>{t('pricing customize opacity')}</ListItem>
        <ListItem>{t('pricing auto snap')}</ListItem>
        <ListItem>{t('pricing auto upload')}</ListItem>
      </UnorderedList>
      <Button size="lg" width="100%" variant={isHero ? 'solid' : 'outline'}>
        { buttonText }
      </Button>
    </Flex>
  )
}

export default withTranslation('toolbox')(PricingCard)