import React from 'react'
import { useColorModeValue as mode, Box, Image, useColorMode } from '@chakra-ui/react'
import Hero from './components/Hero'
import FeaturesOverview from './components/FeaturesOverview'
import Feature from './components/Feature'
import Pricing from './components/Pricing'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FCC } from 'types/utils'

const responsiveRadius = ["2px", "2px", "4px", "4px", "8px"]

const VariableToolsPage: FCC<WithTranslation> = ({t}) => {
  const { colorMode } = useColorMode()

  return (
    <>
      <Box
        paddingTop="72px"
        color="text.primary"
        background={mode('gradient.linearBGLight', 'gradient.linearBGDark')}
      >
        <Hero/>
        <FeaturesOverview/>
        <Feature
          featureType="bulk-editing"
          featureTitle={t("bulk editing title")}
          title={t("feature bulk editing")}
          description={t("feature bulk editing description")}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width={["100%", "44.8%"]}
            src={`/variable-tools/bulk-editing/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="0"
            left="49%"
            width="21.1%"
            src={`/variable-tools/bulk-editing/select-variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="26.9%"
            bottom="0"
            right="0"
            src={`/variable-tools/bulk-editing/code-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            top="16%"
            left="48.6%"
            width="11.3%"
            src={`/variable-tools/bulk-editing/editing-text-${colorMode}.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            top="16%"
            right="0"
            width="6.4%"
            src="/variable-tools/bulk-editing/dev-mode.png"
          />
        </Feature>
        <Feature
          featureType="copy-paste"
          featureTitle={t("copy paste title")}
          title={t("feature copy paste")}
          description={t("feature copy paste description")}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width={["100%", "44.8%"]}
            src={`/variable-tools/copy-paste/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="0"
            left="49%"
            width="21.1%"
            src={`/variable-tools/copy-paste/copy-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="26.9%"
            bottom="0"
            right="0"
            src={`/variable-tools/copy-paste/paste-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
        </Feature>
        <Feature
          featureType='bulk-renaming'
          featureTitle={t("bulk renaming title")}
          title={t("feature bulk renaming")}
          description={t("feature bulk renaming description")}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width={["100%", "44.8%"]}
            src={`/variable-tools/bulk-renaming-1/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="51.6%"
            bottom="0"
            right="0"
            src={`/variable-tools/bulk-renaming-1/variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            top="18%"
            left="60%"
            width="16.7%"
            src={`/variable-tools/bulk-renaming-1/select-${colorMode}.png`}
            borderRadius={["4px", "4px", "8px", "8px", "12px"]}
            boxShadow="lg"
          />
        </Feature>
        <Feature
          featureType='bulk-renaming'
          featureTitle={t("bulk renaming title")}
          title={t("feature bulk renaming 2")}
          description={t("feature bulk renaming description 2")}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width={["100%", "44.8%"]}
            src={`/variable-tools/bulk-renaming-2/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="51.6%"
            bottom="0"
            right="0"
            src={`/variable-tools/bulk-renaming-2/variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            top="18%"
            left="48.5%"
            width="26.9%"
            src={`/variable-tools/bulk-renaming-2/custom-replace-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
        </Feature>
        <Feature
          featureType='import-export'
          featureTitle={t("import export title")}
          title={t("feature import export")}
          description={t("feature import export description")}
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width={["56.5%", "25.5%"]}
            src={`/variable-tools/import-export/plugin-import-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            position="absolute"
            top="0"
            left={["43.3%", "20%"]}
            width={["56.5%", "25.5%"]}
            src={`/variable-tools/import-export/plugin-export-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="15%"
            right="14%"
            width="37.6%"
            src={`/variable-tools/import-export/json-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="37.6%"
            bottom="0"
            right="0"
            src={`/variable-tools/import-export/css-${colorMode}.png`}
            borderRadius={responsiveRadius}
          />
        </Feature>
        <Feature
          featureType='swap'
          featureTitle={t("swap title")}
          title={t("feature swap")}
          description={t("feature swap description")}
        >
          <Image
            position="absolute"
            top="0"
            left={["21.75%", "0"]}
            width={["56.5%", "25.5%"]}
            src={`/variable-tools/swap/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="0"
            left="38%"
            width="21.2%"
            src={`/variable-tools/swap/select-styles-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="28%"
            left="62%"
            width="14.1%"
            src={`/variable-tools/swap/arrow-${colorMode}.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="21.2%"
            bottom="0"
            right="0"
            src={`/variable-tools/swap/select-variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
        </Feature>
        <Feature
          featureType='color-guide'
          featureTitle={t("color guide title")}
          title={t("feature color guide")}
          description={t("feature color guide description")}
        >
          <Image
            position="absolute"
            top="0"
            left={["21.75%", "0"]}
            width={["56.5%", "25.5%"]}
            src={`/variable-tools/guide-1/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="54.5%"
            bottom="1%"
            right="0"
            src={`/variable-tools/guide-1/guide-${colorMode}.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="7.3%"
            top="27%"
            left="53%"
            src={`/variable-tools/guide-1/arrow.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="12%"
            left="28%"
            width="24%"
            src={`/variable-tools/guide-1/variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
        </Feature>
        <Feature
          featureType='color-guide'
          featureTitle={t("color guide title")}
          title={t("feature color guide 2")}
          description={t("feature color guide description 2")}
        >
          <Image
            position="absolute"
            top="0"
            left={["21.75%", "0"]}
            width={["56.5%", "25.5%"]}
            src={`/variable-tools/guide-2/plugin-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="54.5%"
            bottom="2%"
            left="28%"
            src={`/variable-tools/guide-2/guide-${colorMode}.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            width="7.3%"
            top="26%"
            left="68%"
            src={`/variable-tools/guide-2/arrow.png`}
          />
          <Image
            display={["none", "block"]}
            position="absolute"
            bottom="12%"
            right="0"
            width="24%"
            src={`/variable-tools/guide-2/variables-${colorMode}.png`}
            borderRadius={responsiveRadius}
            boxShadow="lg"
          />
        </Feature>
        <Pricing/>
      </Box>
    </>
  )
}

export default withTranslation('variableTools')(VariableToolsPage)
