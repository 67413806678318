export const appBase = process.env.REACT_APP_BASE_URL
export const serverBase = process.env.REACT_APP_SERVER_API_BASE;

export const languages = {
  'en-US': 'English',
  'zh-CN': '中文',
}

export const languageMaps = {
  svg: 'html',
  react: 'js',
  react_ts: 'js',
  vue: 'html',
  android_xml: 'xml'
}

export const formatMaps = {
  svg: 'svg',
  react: 'jsx',
  react_ts: 'tsx',
  vue: 'vue',
  android_xml: 'xml'
}
