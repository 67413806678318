import React from 'react'
import { useColorModeValue as mode, Box, Flex, Heading, Image, Icon, Text } from "@chakra-ui/react"
import { Info } from 'react-feather';

interface Props {
  image: string;
  title: string;
  description: string;
  extraTip?: string;
  isComingSoon?: boolean;
}

function FeatureCard ({ image, title, description, extraTip, isComingSoon }: Props) {
  return (
    <Box opacity={isComingSoon ? 0.64 : 1}>
      <Box
        position="relative"
        paddingTop="100%"
        marginBottom="16px"
      >
        <Image
          position="absolute"
          top="0"
          width="100%"
          src={image}
          borderRadius="24px"
        />
      </Box>
      <Box>
        <Heading as="h3" fontSize="20px" fontWeight="500" mb="8px">{ title }</Heading>
        <Text mb="4px" fontSize="16px" color={mode('black.66', 'white.66')}>{ description }</Text>
        {
          extraTip &&
          <Flex alignItems="center" fontSize="12px" fontWeight="500" color="blue.500">
            <Icon as={Info} width="14px" height="14px" mr="4px"/>
            { extraTip }
          </Flex>
        }
      </Box>
    </Box>
  )
}

export default FeatureCard