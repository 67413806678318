import { serverBase } from "utils/constants";
import { getAuthHeaders } from "utils/api"

export async function getTeam () {
  return await fetch(
    `${serverBase}/team`,
    {
      headers: getAuthHeaders()
    }
  )
    .then(res => res.json())
}
