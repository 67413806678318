import React from 'react'
import { Box, Flex, Heading, ListItem, SimpleGrid, Text, UnorderedList } from "@chakra-ui/react"
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'
import CenteredBox from 'components/common/CenteredBox'
import { BookOpen, Copy, Download, Edit, Edit3, Repeat } from 'react-feather'
import Button from 'components/custom/Button'

export type FeatureType = 'bulk-editing'|'copy-paste'|'bulk-renaming'|'import-export'|'swap'|'color-guide'

export const FeatureIcon = ({ featureType, size=20 }: { featureType: FeatureType, size?: number }) => {
  switch (featureType) {
    case 'bulk-editing':
      return <Edit3 size={size}/>

    case 'copy-paste':
      return <Copy size={size}/>

    case 'bulk-renaming':
      return <Edit size={size}/>

    case 'import-export':
      return <Download size={size}/>

    case 'swap':
      return <Repeat size={size}/>

    case 'color-guide':
      return <BookOpen size={size}/>

    default:
      return <Edit3 size={size}/>
  }
}

const Pricing: FCC<WithTranslation> = ({t}) => {
  return (
    <Box background="linear-gradient(180deg, rgba(15, 111, 255, 0.02) 0%, rgba(15, 111, 255, 0.05) 100%)">
    <CenteredBox>
      <Box paddingX="16px" paddingY="80px">
        <SimpleGrid columns={[2, 2, 4, 5, 5]} gap="24px" alignItems="center">
          <Box gridColumn={["1/3", "1/3", "1/3", "1/4", "1/4"]}>
            <Heading as="h1" mb="8px" fontSize={['32px', '48px', '40px']} fontWeight={500}>
              {t('from trial')}
            </Heading>
            <Text mb="8px" fontSize={20}>{t('from trial description')}</Text>
            <Text fontSize={14} color="text.secondary">
              <Text as="span" mr="4px" color="orange">*</Text>
              {t('from trial note')}
            </Text>
          </Box>
          <Flex flexDirection="column">
            <Heading as="h2" fontSize={['18px', '20px']} fontWeight={500}>
              {t('free version')}
            </Heading>
            <Heading as="h3" mb="16px" fontSize={['24px', '32px', '40px']} fontWeight={500} color="primary">
              $0
            </Heading>
            <UnorderedList mb="16px" fontSize={16}>
              <ListItem>{t("free text 1")}</ListItem>
              <ListItem>{t("free text 2")}</ListItem>
              <ListItem>{t("free text 3")}</ListItem>
            </UnorderedList>
            <Button variant="outline" as="a" target="_blank" href="https://s.heronhq.com/vartools">{t('try button')}</Button>
          </Flex>
          <Flex flexDirection="column" alignSelf="stretch">
            <Heading as="h2" fontSize={['18px', '20px']} fontWeight={500}>
              {t('pro version')}
            </Heading>
            <Heading as="h3" mb="16px" fontSize={['24px', '32px', '40px']} fontWeight={500} color="primary">
              $16
            </Heading>
            <UnorderedList mb="16px" flex={1} fontSize={16}>
              <ListItem>{t("pro text 1")}</ListItem>
              <ListItem>{t("pro text 2")}</ListItem>
            </UnorderedList>
            <Button as="a" target="_blank" href="https://s.heronhq.com/buy-hvtools">{t('buy button')}</Button>
          </Flex>
        </SimpleGrid>
      </Box>
    </CenteredBox>
    </Box>
  )
}

export default withTranslation('variableTools')(Pricing)
