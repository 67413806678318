async function increment(payload: any) {
  return new Promise(resolve => {
    if (payload) {
      console.log('payload', payload)
      setTimeout(() => {
        resolve(payload)
      }, 1000)
    }
  })
}

export default increment
