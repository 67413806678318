import styled from "@emotion/styled"
import React from "react"

const StyledSVG = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
`

function Hexagon ({
    percentageSize=100,
    opacity=1,
    strokeWidth=1,
    color='currentColor',
    y=0
  }: {
    percentageSize?: number,
    opacity?: number,
    strokeWidth?: number,
    color?: string,
    y?: number
}) {
  return (
    <StyledSVG
      className="outline-vector"
      width={percentageSize+'%'}
      height={percentageSize+'%'}
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginTop: `-${percentageSize/2}%`,
        marginLeft: `-${percentageSize/2}%`,
        transform: `translateY(${y}px) rotateX(-30deg)`
      }}
    >
      <path opacity={opacity} d="M62.7115 1.50481L106.673 26.886C108.994 28.2258 110.423 30.7017 110.423 33.3812V84.1436C110.423 86.8231 108.994 89.299 106.673 90.6388L62.7115 116.02C60.391 117.36 57.532 117.36 55.2115 116.02L11.25 90.6388C8.92949 89.299 7.5 86.8231 7.5 84.1436V33.3812C7.5 30.7017 8.92949 28.2258 11.25 26.886L55.2115 1.50481C57.532 0.165063 60.391 0.165064 62.7115 1.50481Z" stroke={color} strokeWidth={strokeWidth} vectorEffect="non-scaling-stroke"/>
    </StyledSVG>
  )
}

export default Hexagon
