import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Icon,
  Button,
  Image,
  Text
} from "@chakra-ui/react"
import supabase from 'utils/supabase'
import { upsertAssociation } from 'api/pluginAuth'
import { Frown, Smile } from 'react-feather'
import AuthContainer from './components/AuthContainer'
import { withTranslation, WithTranslation } from 'react-i18next'

const PluginAuth: FC<WithTranslation> = ({t}) => {
  const params = useParams()
  const clientId = params.clientId
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<boolean>(false)
  const user = supabase.auth.user()
  const userId = user?.id||''

  async function createAssociation () {
    if (clientId && userId) {
      setIsLoading(true)
      const { error } = await upsertAssociation({id: clientId, user_id: userId})
      setIsLoading(false)
      if (error) {
        setError(error)
        console.error(error)
      } else {
        setSuccess(true)
      }
    }
  }

  if (error?.code==='23505') {
    return (
      <AuthContainer>
        <Box textAlign="center">
          <Icon as={Frown} width="48px" height="48px" mb="8px" color="gray.300"/>
          <Text as="p">{t('link expired')}</Text>
        </Box>
      </AuthContainer>
    )
  }

  if (success) {
    return (
      <AuthContainer>
        <Box textAlign="center">
          <Icon as={Smile} width="48px" height="48px" mb="8px" color="green.500"/>
          <Text as="p">{t('logged in, back to figma')}</Text>
        </Box>
      </AuthContainer>
    )
  }

  return (
    <AuthContainer>
      <Box textAlign="center">
        <Image src="/home/Icon baker.svg" width="48px" margin="0 auto" mb="8px"/>
        <Text mb="16px">{t('log in for heron icon baker')}</Text>
        <Button
          size="md"
          w="200px"
          disabled={isLoading}
          onClick={createAssociation}
        >{ isLoading ? t('logging in') : t('log in')}</Button>
      </Box>
    </AuthContainer>
  )
}

export default withTranslation('auth')(PluginAuth)
