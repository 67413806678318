import React from 'react';
import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/react";
import FeatureCard from './FeatureCard'
import { withTranslation, WithTranslation } from 'react-i18next';
import type { FCC } from 'types/utils';

const Features: FCC<WithTranslation> = ({t}) => {
  return (
    <Center>
      <Box maxWidth="960px" paddingX="16px" paddingY="128px">
        <Heading as="h1" textAlign="center" fontSize={['24px', '32px', '40px']} fontWeight="bold" mb="80px">
          {t('amazing features')}
        </Heading>
        <SimpleGrid columns={[1, 2, 3]} gap="40px">
          <FeatureCard
            image="/transfer/no-cable.png"
            title={t('no cable')}
            description={t('no cable description')}
          />
          <FeatureCard
            image="/transfer/compress.png"
            title={t('auto compress')}
            description={t('auto compress description')}
            extraTip={t('auto compress tip')}
          />
          <FeatureCard
            image="/transfer/auto-uoload.png"
            title={t('auto upload')}
            description={t('auto upload description')}
            extraTip={t('coming soon')}
            isComingSoon
          />
          <FeatureCard
            image="/transfer/customize-scale.png"
            title={t('customize scale')}
            description={t('customize scale description')}
          />
          <FeatureCard
            image="/transfer/customize-opacity.png"
            title={t('customize opacity')}
            description={t('customize opacity description')}
          />
          <FeatureCard
            image="/transfer/auto-grid.png"
            title={t('auto snap')}
            description={t('auto snap description')}
          />
        </SimpleGrid>
      </Box>
    </Center>
  )
}

export default withTranslation('toolbox')(Features)