import { extendTheme } from '@chakra-ui/react'
import Button from './button'
import Select from './select'
import Input from './input'
import Textarea from './textarea'
import { basicColors, semanticColors } from './colors'
import globalStyles from './globalStyles'
import shadows from './shadows'

const theme = extendTheme({
  colors: basicColors,
  semanticTokens: {
    colors: semanticColors
  },
  styles: globalStyles,
  fonts: {
    body: '"Poppins", "Inter", "PingFang SC", "PingFang", "Lantinghei SC", "HiraginoSansGB-W3", "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, sans-serif',
    heading: '"Poppins", "Inter", "PingFang SC", "PingFang", "Lantinghei SC", "HiraginoSansGB-W3", "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  shadows,
  components: {
    Button,
    Input,
    Textarea,
    Select,
    Radio: {
      sizes: {
        md: {
          label: {
            fontSize: 'sm'
          }
        }
      }
    },
    Alert: {
      baseStyle: {
        container: {
          py: 2,
          borderRadius: 'lg'
        }
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm'
      }
    },
    Tabs: {
      defaultProps: {
        size: 'sm'
      }
    }
  }
});
// console.log(theme)
export default theme
