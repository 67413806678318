import { mode } from "./utils"

export const basicColors = {
  blue: {
    50: '#f2f7ff',
    100: '#e7f1ff',
    200: '#b2d3ff',
    300: '#73aeff',
    400: '#3c8cff',
    500: '#0f6fff',
    600: '#005fee',
    700: '#004dc1',
    800: '#0042a4',
    900: '#003687'
  },
  gray: {
    50: '#F7F9FB',
    100: '#F1F4F6',
    200: '#E7EAEE',
    300: '#D6DADE',
    400: '#B2B9C1',
    500: '#8D9299',
    600: '#5B606A',
    700: '#3D4148',
    800: '#26292F',
    900: '#1F2025',
  },
  white: {
    100: '#FFF',
    86: 'rgba(255, 255, 255, 0.86)',
    66: 'rgba(255, 255, 255, 0.66)',
    56: 'rgba(255, 255, 255, 0.56)',
    36: 'rgba(255, 255, 255, 0.36)',
    16: 'rgba(255, 255, 255, 0.16)',
    6: 'rgba(255, 255, 255, 0.06)'
  },
  black: {
    100: '#000',
    86: 'rgba(0, 0, 0, 0.86)',
    66: 'rgba(0, 0, 0, 0.66)',
    56: 'rgba(0, 0, 0, 0.56)',
    36: 'rgba(0, 0, 0, 0.36)',
    16: 'rgba(0, 0, 0, 0.16)',
    6: 'rgba(0, 0, 0, 0.06)'
  },
  common: {
    blue: '#0F6FFF',
    yellow: '#FFD600',
    green: '#0ACF83'
  },
  gradient: {
    linearBGLight: 'linear-gradient(180deg, rgba(242, 247, 255, 0) 0%, #F2F7FF 100%)',
    linearBGDark: 'linear-gradient(180deg, #1a1d21 0%, #0a0b0d 100%)'
  }
}

export const semanticColors = {
  'primary': mode('blue.500', 'blue.400'),
  'primary.hover': mode('blue.600', 'blue.500'),
  'primary.pressed': mode('blue.700', 'blue.600'),
  'text.primary': mode('black.100', 'white.100'),
  'text.secondary': mode('black.86', 'white.86'),
  'text.tertiary': mode('black.56', 'white.56'),
  'bg': mode('white', 'gray.900'),
  'bg.shallow': mode('gray.50', 'gray.800'),
  'bg.code': mode('gray.100', 'gray.800'),
  'bg.hover': mode('gray.200', 'gray.600'),
  'bg.selected': mode('blue.100', 'blue.900'),
  'bg.theme.shallow': mode('blue.50', 'blue.800'),
  'bg.mbsp.upgrade': mode('blue.100', 'gray.700'),
  'bg.mbsp.free': mode('gray.100', 'gray.700'),
  'bg.mbsp.pro': mode('gray.700', 'gray.100'),
  'text.mbsp.pro': mode('white', 'gray.900'),
  'border': mode('gray.200', 'gray.800'),
}
