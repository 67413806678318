import React, { FC, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CheckCircle } from 'react-feather'
import {
  Box,
  Icon,
  Text
} from "@chakra-ui/react"
import AuthContainer from './components/AuthContainer'
import supabase from 'utils/supabase'

const MagicPage: FC<WithTranslation> = ({t}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const backUrl = searchParams.get('backUrl')

  useEffect(() => {
    supabase.auth.onAuthStateChange(e => {
      if (e==='SIGNED_IN') {
        dispatch({
          type: 'UPDATE_USER',
          payload: supabase.auth.user()
        })
        if (backUrl) {
          navigate(decodeURIComponent(backUrl))
        }
      }
    })
  }, [backUrl, dispatch, navigate])

  return (
    <AuthContainer>
      <Box textAlign="center">
        <Icon as={CheckCircle} width="48px" height="48px" mb="8px" color="green.300"/>
        <Text>{ t('logged in, navigate soon') }</Text>
      </Box>
    </AuthContainer>
  )

};

export default withTranslation('auth')(MagicPage);
