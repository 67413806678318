import React from 'react'
import { withTranslation } from 'react-i18next'
import { useColorModeValue as mode, Box, Heading, Text, SimpleGrid } from "@chakra-ui/react"
import { GitHub } from 'react-feather'
import CenteredBox from 'components/common/CenteredBox'
import Product from './Product'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const Hero: FCC<WithTranslation> = ({t}) => {

  return (
    <Box
      pt="72px"
      pb={['40px', '80px']}
      background={ mode('gradient.linearBGLight', 'gradient.linearBGDark') }
    >
      <CenteredBox p="16px">
        <Heading
          as="h1"
          textAlign="center"
          fontSize={['32px', '40px', '64px']}
          fontWeight="bold"
          mt="80px"
          mb="16px"
        >{ t('hero title') }</Heading>
        <Text
          textAlign="center"
          fontSize="20"
          mb="80px"
          color={mode('black.66', 'white.66')}
        >{t('hero description')}</Text>
        <SimpleGrid columns={[1, 2, 3, 3, 4]} gap="20px">
          <Product
            logo="/home/variable-tools.svg"
            link="/variable-tools"
            title="Varibale tools"
            description={t('variable tools description')}
            installLink="https://www.figma.com/community/plugin/1285971940276913396"
          />
          <Product
            logo="/home/Theme tools.svg"
            link="/theme-tools"
            title="Theme tools"
            description={t('theme tools description')}
            installLink="https://www.figma.com/community/plugin/1151162728819593106/Heron-theme-tools"
          />
          <Product
            logo="/home/Icon baker.svg"
            title="Icon baker"
            description={t('icon baker description')}
            installLink="https://www.figma.com/community/plugin/1168719611525652627/heron-icon-baker"
          />
          <Product
            logo="/home/Toolbox.svg"
            title="Toolbox"
            description={t('toolbox description')}
            installLink="https://www.figma.com/community/plugin/1146243428006792173"
          />
          <Product
            logo="/home/Stylebook.svg"
            title="Stylebook"
            description={t('stylebook description')}
            installLink="https://www.figma.com/community/plugin/1204451606287653924"
          />
          <Product
            logo="/home/Handoff.svg"
            title="Handoff"
            description={t('handoff description')}
            installLink="https://www.figma.com/community/plugin/830051293378016221/Heron-Handoff"
          />
          <Product
            logo="/home/viewer-plugin.svg"
            title="Viewer Plugin"
            description={t('view plugin description')}
            installicon={GitHub}
            installText={t('view plugin text')}
            installLink="https://github.com/leadream/figma-viewer-chrome-plugin"
          />
        </SimpleGrid>
      </CenteredBox>
    </Box>
  )
}

export default withTranslation('home')(Hero);
