import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './rootReducer'
import sagas from './rootSaga' 

// https://blog.lishunyang.com/2021/04/migrate-dva-to-ts.html#%E6%B2%A1%E6%9C%89%E5%85%A8%E5%B1%80%E7%9A%84-state-%E7%B1%BB%E5%9E%8B
// 主要参考：https://github.com/ctq123/blogs/issues/2
const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const sagaMiddleware = createSagaMiddleware()
const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  // other store enhancers if any
);

const store = createStore(
  reducer,
  enhancer
)
sagaMiddleware.run(sagas)

export default store
