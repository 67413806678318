import React from "react"
import { useRoutes } from 'react-router-dom'

import PublicLayout from 'layouts/public'

import Home from 'pages/home'
import Login from 'pages/auth/login'
import MagicPage from 'pages/auth/magic'
import PluginAuth from 'pages/auth/plugin-auth'
import FigmaOAuth from 'pages/auth/figma-oauth'
import IconBaker from 'pages/landing/icon-baker'
import VariableTools from 'pages/landing/variable-tools'
import ThemeTools from 'pages/landing/theme-tools'
import Toolbox from 'pages/landing/toolbox'
import NotFound from 'pages/NotFound'

import { withAntiAuthProtector, withAuthProtector } from "./hoc"

const Router = () => {
  const element = [
    {
      path: '/',
      element: <PublicLayout/>,
      children: [
        { path: '/', element: <Home /> },
        { path: '/login', element: withAntiAuthProtector(<Login />) },
        { path: '/magic', element: <MagicPage /> },
        { path: '/plugin-auth/:clientId', element: withAuthProtector(<PluginAuth />) },
        { path: '/figma-oauth', element: <FigmaOAuth /> },
        { path: '/variable-tools', element: <VariableTools /> },
        { path: '/icon-baker', element: <IconBaker /> },
        { path: '/theme-tools', element: <ThemeTools /> },
        { path: '/toolbox', element: <Toolbox /> },
      ]
    },
    {
      path: "*",
      element: <NotFound/>
    }
  ]

  return useRoutes(element);
}

export default Router
