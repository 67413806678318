import React from 'react'
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

import type { FCC } from 'types/utils'
import CenteredBox from 'components/common/CenteredBox'
import { FeatureIcon, FeatureType } from './FeaturesOverview'

interface Props {
  featureType: FeatureType;
  featureTitle: string;
  title: string;
  description: string;
}

const Feature: FCC<Props> = ({ featureType, featureTitle, title, description, children }) => {

  return (
    <CenteredBox>
      <Box paddingX="16px" paddingY={["32px", "48px", "64px", "80px", "112px"]}>
        <Heading as="h6" size="sm" display="flex" gap="8px" alignItems="center" mb={["16px", "16px", "8px"]} color="primary">
          <FeatureIcon featureType={featureType} size={16}/>
          { featureTitle }
        </Heading>
        <Flex direction={["column", "column", "row"]} gap={["8px", "8px", "40px"]} mb="64px">
          <Heading as="h3" flex={1}>{ title }</Heading>
          <Text flex={1} fontSize="18px">{ description }</Text>
        </Flex>
        <Box position="relative" paddingTop={["107%", "48%"]}>
          { children }
        </Box>
      </Box>
    </CenteredBox>
  )
}

export default Feature
