import React from 'react'
import { useColorModeValue as mode, Box } from '@chakra-ui/react'
import Hero from './components/Hero'
import Features from './components/Features'
import Pricing from './components/Pricing'

const ThemeToolsPage = () => {
  return (
    <Box
      paddingTop="72px"
      background={mode('gradient.linearBGLight', 'gradient.linearBGDark')}
    >
      <Hero/>
      <Features/>
      <Pricing/>
    </Box>
  )
}

export default ThemeToolsPage
