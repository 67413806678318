import React, { useRef, useState } from 'react'
import { useColorModeValue as mode, Center, Heading, Text, Image, Button, Flex, Box, useColorMode } from "@chakra-ui/react"
import Plugin from './Plugin'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useGsap } from 'utils/hooks'
import ScrollTrigger from 'gsap/ScrollTrigger'

import type { FCC } from 'types/utils'

const Hero:FCC<WithTranslation> = ({t}) => {
  const [height, setHeight] = useState(0)
  const root = useRef(null)
  const stage = useRef(null)
  const { colorMode } = useColorMode()
  const pics = colorMode==='light' ? ['Light', 'Dark'] : ['Dark', 'Light']

  useGsap(root, gsap => {
    ScrollTrigger.create({
      pin: stage.current,
      start: "center center",
      end: "+=500",
      // markers: true,
      onUpdate (animation) {
        setHeight(animation.progress*100)
      }
    })
  })

  return (
    <Center
      flexDirection="column"
      paddingX="16px"
      paddingY="128px"
      ref={root}
    >
      <Heading
        as="h1"
        maxWidth="900px"
        mb="8px"
        textAlign="center"
        fontSize={['28px', '32px', '48px']}
        fontWeight="bold"
        lineHeight="64px"
      >
        {t('hero title')}
      </Heading>
      <Text textAlign="center" mb="32px" fontSize="20px" color={mode('black.66', 'white.66')}>
        {t('hero description')}
      </Text>
      <Flex marginBottom="80px">
        <Button
          as="a"
          href="https://www.figma.com/community/plugin/1151162728819593106"
          target="_blank"
          size="sm"
          width="128px"
          height="40px"
          mr="16px"
        >{t('try for free')}</Button>
        <Button
          as="a"
          href="#pricing"
          size="sm"
          width="128px"
          height="40px"
          variant="outline"
        >{t('buy a license')}</Button>
      </Flex>
      <Box
        ref={stage}
        width="100%"
        maxWidth="800px"
        marginBottom="500px"
      >
        <Box
          position="relative"
          paddingTop="60%"
        >
          <Plugin
            pos={height}
          />
          <Box
            position="absolute"
            bottom="0"
            right="0"
            width="80%"
            borderRadius="8px"
            overflow="hidden"
          >
            <Image
              width="100%"
              src={`/theme-tools/${pics[0]}.png`}
            />
            <Box
              position="absolute"
              bottom="0"
              right="0"
              width="100%"
              style={{
                height: `${height}%`
              }}
              background="black"
              overflow="hidden"
            >
              <Image
                position="absolute"
                bottom="0"
                width="100%"
                src={`/theme-tools/${pics[1]}.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}

export default withTranslation('themeTools')(Hero)
