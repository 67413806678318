import React, { useState } from 'react'
import { Button, Flex, Input } from "@chakra-ui/react"
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const EarlyAccessForm: FCC<WithTranslation> = ({ t }) => {
  const [email, setEmail] = useState('')

  function handleSubmit () {
    window.open(`https://airtable.com/shrt9hpBqqqYO1y5s?prefill_Email=${email}`, '_self')
  }

  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      alignItems="center"
      gap="8px"
    >
      <Input
        size="md"
        width="260px"
        name="email"
        placeholder={t('your email')}
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <Button
        size="md"
        flexShrink={0}
        width={['260px', 'auto']}
        onClick={handleSubmit}
      >{t('get early access')}</Button>
    </Flex>
  )
}

export default withTranslation('iconBaker')(EarlyAccessForm)
