import React from 'react'
import { Box, Heading, SimpleGrid } from "@chakra-ui/react"
import OverviewItem from './OverviewItem'
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'
import CenteredBox from 'components/common/CenteredBox'
import { BookOpen, Copy, Download, Edit, Edit3, Repeat } from 'react-feather'

export type FeatureType = 'bulk-editing'|'copy-paste'|'bulk-renaming'|'import-export'|'swap'|'color-guide'

export const FeatureIcon = ({ featureType, size=20 }: { featureType: FeatureType, size?: number }) => {
  switch (featureType) {
    case 'bulk-editing':
      return <Edit3 size={size}/>

    case 'copy-paste':
      return <Copy size={size}/>

    case 'bulk-renaming':
      return <Edit size={size}/>

    case 'import-export':
      return <Download size={size}/>

    case 'swap':
      return <Repeat size={size}/>

    case 'color-guide':
      return <BookOpen size={size}/>

    default:
      return <Edit3 size={size}/>
  }
}

const FeaturesOverview: FCC<WithTranslation> = ({t}) => {
  return (
    <CenteredBox>
      <Box paddingX="16px" paddingY={["40px", "80px"]}>
        <Heading as="h1" textAlign="center" fontSize={['32px', '48px', '56px']} fontWeight={500} mb={["32px", "64px"]}>
          {t('feature overview title')}
        </Heading>
        <SimpleGrid columns={[1, 1, 2, 2, 3]} gap="32px">
          <OverviewItem
            featureType='bulk-editing'
            title={t('bulk editing title')}
            description={t('bulk editing description')}
          />
          <OverviewItem
            featureType='copy-paste'
            title={t('copy paste title')}
            description={t('copy paste description')}
          />
          <OverviewItem
            featureType='bulk-renaming'
            title={t('bulk renaming title')}
            description={t('bulk renaming description')}
          />
          <OverviewItem
            featureType='import-export'
            title={t('import export title')}
            description={t('import export description')}
          />
          <OverviewItem
            featureType='swap'
            title={t('swap title')}
            description={t('swap description')}
          />
          <OverviewItem
            featureType='color-guide'
            title={t('color guide title')}
            description={t('color guide description')}
          />
        </SimpleGrid>
      </Box>
    </CenteredBox>
  )
}

export default withTranslation('variableTools')(FeaturesOverview)
