import React from 'react';
import { useColorModeValue as mode, useColorMode, Box, Tooltip } from '@chakra-ui/react'
import { Sun, Moon } from 'react-feather'
import { withTranslation, WithTranslation } from 'react-i18next';
import { FCC } from 'types/utils';

const ThemeSwitcher:FCC<WithTranslation> = function ({t}) {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Tooltip label={t('switch theme')} aria-label={t('switch theme')}>
      <Box onClick={toggleColorMode} padding="4px" cursor="pointer" color={mode('gray.600', 'gray.400')}>
        {
          colorMode === 'light' ?
          <Moon size={20}/> :
          <Sun size={20}/>
        }
      </Box>
    </Tooltip>
  )
}

export default withTranslation('common')(ThemeSwitcher)
