import React, { useRef } from 'react'
import { Center, Heading, Text, Image, Box } from "@chakra-ui/react"
import { withTranslation } from 'react-i18next'
import { useGsap } from 'utils/hooks'
import EarlyAccessForm from './EarlyAccessForm'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const Hero:FCC<WithTranslation> = ({t}) => {
  const root = useRef(null)

  useGsap(root, gsap => {
    gsap.to('.vscode', {
      y: 64,
      scrollTrigger: {
        trigger: root.current,
        start: 'top top+=72',
        end: 'bottom center',
        scrub: 1,
      }
    })
    gsap.to('.plugin', {
      y: 40,
      scrollTrigger: {
        trigger: root.current,
        start: 'top top+=72',
        end: 'bottom center',
        scrub: 1,
      }
    })
  })

  return (
    <Center
      flexDirection="column"
      paddingX="16px"
      paddingY="128px"
      ref={root}
    >
      <Heading
        as="h1"
        maxWidth="800px"
        mb="8px"
        textAlign="center"
        fontSize={['32px', '40px', '56px']}
        fontWeight="bold"
        lineHeight={1.5}
      >
        {t('hero title')}
      </Heading>
      <Text textAlign="center" mb="32px" fontSize={['16px', '16px', '22px']} color="text.tertiary">
        {t('hero description')}
      </Text>
      <EarlyAccessForm/>
      <Box
        width="100%"
        maxWidth="800px"
        marginTop="80px"
      >
        <Box
          position="relative"
          paddingTop="54%"
        >
          <Image
            className="vscode"
            position="absolute"
            bottom="0"
            right="0"
            height="100%"
            src="/icon-baker/vscode.png"
          />
          <Image
            className="plugin"
            position="absolute"
            bottom="0"
            left="0"
            height="78%"
            src="/icon-baker/plugin.png"
          />
        </Box>
      </Box>
    </Center>
  )
}

export default withTranslation('iconBaker')(Hero)
