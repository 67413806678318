const Select = {
  defaultProps: {
    size: 'sm',
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'lg'
      }
    }
  }
}

export default Select
