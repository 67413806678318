import React from 'react';
import { Box } from "@chakra-ui/react";
import Hero from './components/Hero';

const HomePage = () =>
  <Box>
    <Hero/>
  </Box>

export default HomePage;
