import React from 'react'
import { Box, Heading, Text } from "@chakra-ui/react"
import CenteredBox from 'components/common/CenteredBox'
import EarlyAccessForm from './EarlyAccessForm'
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const GetEarlyAccess: FCC<WithTranslation> = ({t}) => {
  return (
    <Box p="64px 16px">
      <CenteredBox textAlign="center">
        <Heading
          as="h1"
          mb="16px"
          textAlign="center"
          fontSize={['24px', '32px', '40px']}
          fontWeight="bold"
        >{t('get early access now')}</Heading>
        <Text textAlign="center" fontSize="16px" mb="24px" color="text.tertiary">{t('notify you')}</Text>
        <EarlyAccessForm/>
      </CenteredBox>
    </Box>
  )
}

export default withTranslation('iconBaker')(GetEarlyAccess)
