import React, { FC } from "react"
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'

import type { InputProps } from '@chakra-ui/react'

interface Props {
  formik: any;
  name: string;
  label?: string;
}

const TextField: FC<Props&InputProps> = ({formik, name, label, ...props}) => {
  return (
    <FormControl id={name} mb="16px" isInvalid={!!(formik.errors[name] && formik.touched[name])}>
      { !!label && <FormLabel>{label}</FormLabel> }
      <Input
        id={name}
        name={name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        {...props}
      />
      {(formik.touched[name] && formik.errors[name]) ? (
        <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
      ) : null}
    </FormControl>
  )
}

export default TextField
