import React from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Icon, Text } from '@chakra-ui/react'

import type { As } from '@chakra-ui/react'
import { WithTranslation } from 'react-i18next'
import type { FCC } from 'types/utils'

interface Props {
  icon: As;
  format: string;
}

const FormatItem: FCC<WithTranslation&Props> = function ({ icon, format }) {
  return (
    <Box textAlign="center">
      <Icon as={icon} width="64px" height="64px"/>
      <Text as="p" fontSize="16px" fontWeight="500" color="text.secondary">{format}</Text>
    </Box>
  )
}

export default withTranslation('iconBaker')(FormatItem)
