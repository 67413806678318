import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  Heading,
  Text,
  Button
} from "@chakra-ui/react"
import { Mail } from 'react-feather'
import AuthContainer from './components/AuthContainer'
import TextField from 'components/form/TextField'
import Alert from 'components/common/Alert'
import supabase from 'utils/supabase'
import { appBase } from 'utils/constants'

const Login: FC<WithTranslation> = ({t}) => {
  const [ loading, setLoading ] = useState(false)
  const [ loggedIn, setLoggedIn ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState<undefined|string>('')
  const [searchParams] = useSearchParams()
  // encoded back url
  const backUrl = searchParams.get('backUrl') || encodeURIComponent('/dash')

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('it\'s not an email')).required(t('email is required'))
    }),
    onSubmit: async values => {
      setLoading(true)
      let { error } = await supabase.auth.signIn({
        email: values.email
      }, {
        // backUrl 带过去
        redirectTo: `${appBase}/magic?backUrl=${backUrl}`
      })
      setLoading(false)
      if (error) {
        // 登录失败
        console.error(error)
        setErrorMessage(error?.message)
      } else {
        setErrorMessage('')
        setLoggedIn(true)
      }
    },
  });

  return (
    <AuthContainer>
      <Heading as="h3" size="lg" mb="4px">{t('welcome to heron')}</Heading>
      <Text mb="24px" color="black.56">{t('log in or sign up')}</Text>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          formik={formik}
          name="email"
          placeholder={t('your email')}
        />
        <Button
          type="submit"
          w="full"
          mb="12px"
          disabled={loading}
          leftIcon={<Mail size={16}/>}
        >
          {
            loading ?
            t('logging in') :
            ( loggedIn ? t('send again') : t('send magic link') )
          }
        </Button>
        {
          loggedIn &&
          <Alert status="success">
            { t('email sent, check email') }
          </Alert>
        }
        {
          errorMessage &&
          <Alert status="error">
            { t(errorMessage) }
          </Alert>
        }
      </form>
    </AuthContainer>
  );
};

export default withTranslation('auth')(Login);
