import React from 'react';
import { useColorModeValue as mode, Box } from '@chakra-ui/react';
import Hero from './components/Hero';
import Usages from './components/Usages';
import Features from './components/Features';
import Pricing from './components/Pricing';

const IconBakerPage = () => {

  return (
    <>
      <Box
        paddingTop="72px"
        background={mode('gradient.linearBGLight', 'gradient.linearBGDark')}
      >
        <Hero/>
        <Usages/>
        <Features/>
      </Box>
      <Pricing/>
    </>
  )
}

export default IconBakerPage;