import React from 'react';
import { useColorModeValue as mode, Center, Heading, Text, Image, Button, Flex, Box } from "@chakra-ui/react";
import { withTranslation, WithTranslation } from 'react-i18next';
import type { FCC } from 'types/utils';

const Hero:FCC<WithTranslation> = ({t}) =>
  <Center
    flexDirection="column"
    paddingX="16px"
    paddingY="128px"
  >
    <Heading
      as="h1"
      mb="8px"
      textAlign="center"
      fontSize={['32px', '40px', '56px']}
      fontWeight="bold"
      lineHeight="64px"
    >Heron Icon Baker</Heading>
    <Text textAlign="center" mb="32px" fontSize="20px" color={mode('black.66', 'white.66')}>
      {t('hero description')}
    </Text>
    <Flex marginBottom="80px">
      <Button
        as="a"
        href="https://play.pocketcasts.com/podcasts"
        target="_blank"
        size="sm"
        width="128px"
        height="40px"
        mr="16px"
        variant="outline"
      >{t('try for free')}</Button>
      <Button
        as="a"
        href="#pricing"
        size="sm"
        width="128px"
        height="40px"
      >{t('buy now')}</Button>
    </Flex>
    <Box
      width="100%"
      maxWidth="800px"
    >
      <Box
        position="relative"
        paddingTop="52%"
      >
        <Image
          position="absolute"
          bottom="0"
          right="0"
          width="90%"
          src="/icon-baker/web.png"
        />
        <Image
          position="absolute"
          bottom="0"
          left="0"
          width="29%"
          src="/icon-baker/plugin.png"
        />
      </Box>
    </Box>
  </Center>

export default withTranslation('toolbox')(Hero);