import React, { FC, useCallback, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { CheckCircle, Frown } from 'react-feather'
import {
  Box,
  Icon,
  Text
} from "@chakra-ui/react"
import Loading from 'components/common/Loading'
import AuthContainer from './components/AuthContainer'
import { generateFigmaAuth } from 'api/pluginAuth'

let hasRetrieved = false

const FigmaOAuth: FC<WithTranslation> = ({t}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const clientId = searchParams.get('state')

  const authorizeAndInsertPairs = useCallback(async () => {
    if (code && clientId) {
      hasRetrieved = true
      const { error } = await generateFigmaAuth(code, clientId)
      if (error) {
        setError(error.message)
        console.error(error)
      }
      setLoading(false)
    }
  }, [code, clientId])

  useEffect(() => {
    !hasRetrieved && authorizeAndInsertPairs()
  }, [authorizeAndInsertPairs])

  if (loading) {
    return (
      <AuthContainer>
        <Box textAlign="center">
          <Loading size={48} mb="8px" color="gray.300"/>
          <Text as="p">{t('please wait')}</Text>
        </Box>
      </AuthContainer>
    )
  }

  if (error) {
    return (
      <AuthContainer>
        <Box textAlign="center">
          <Icon as={Frown} width="48px" height="48px" mb="8px" color="gray.300"/>
          <Text as="p">{t('link expired')}</Text>
        </Box>
      </AuthContainer>
    )
  }

  return (
    <AuthContainer>
      <Box textAlign="center">
        <Icon as={CheckCircle} width="48px" height="48px" mb="8px" color="green.300"/>
        <Text>{ t('logged in, back to figma') }</Text>
      </Box>
    </AuthContainer>
  )

};

export default withTranslation('auth')(FigmaOAuth);
