import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Center, Button, Flex, Heading } from "@chakra-ui/react"
import Header from 'layouts/public/Header'
import Footer from 'layouts/public/Footer'

const NotFound = () => {
  return (
    <Flex direction="column" height="100vh">
      <Header/>
      <Flex flex="1" alignItems="center" justifyContent="center" marginTop="72px">
        <Center flexDirection="column">
          <Heading as="h3" fontSize="24px" mb="12px">404, Nothing is here.</Heading>
          <Button as={RouterLink} to="/" variant="ghost" size="md">Go back</Button>
        </Center>
      </Flex>
      <Footer/>
    </Flex>
  );
}

export default NotFound
