import { getTeam } from 'api/team'

interface State {
  loading: boolean;
  info: any;
}

interface Action {
  payload: any;
}

const model = {
  name: 'team',
  state: {
    loading: false,
    info: null,
  },
  // reducer
  reducers: {
    'TEAM/START_FETCHING': function(state: State) {
      return { ...state, loading: true }
    },
    'TEAM/FETCH_SUCCESS': function(state: State, action: Action) {
      const { payload } = action
      return { ...state, loading: false, info: payload }
    },
  },
  // saga
  effects: {
    'TEAM/ASYNC_FETCH': function*(_action: Action, { call, put }: any): IterableIterator<any> {
      yield put({ type: 'TEAM/START_FETCHING'})
      const {data, error}: any  = yield call(getTeam)
      if (data) {
        yield put({ type: 'TEAM/FETCH_SUCCESS', payload: data })
      } else {
        throw error
      }
    },
  },
}

export default model
