import React from 'react';
import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 100;
        src: url("/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 100;
        src: url("/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 200;
        src: url("/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 200;
        src: url("/fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 300;
        src: url("/fonts/Poppins/Poppins-Light.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 300;
        src: url("/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 400;
        src: url("/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 400;
        src: url("/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 500;
        src: url("/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 500;
        src: url("/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 600;
        src: url("/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 600;
        src: url("/fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 700;
        src: url("/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 700;
        src: url("/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 800;
        src: url("/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 800;
        src: url("/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
      }
      
      @font-face {
        font-family: 'Poppins';
        font-style:  normal;
        font-weight: 900;
        src: url("/fonts/Poppins/Poppins-Black.ttf") format("truetype");
      }
      @font-face {
        font-family: 'Poppins';
        font-style:  italic;
        font-weight: 900;
        src: url("/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
      }
    `}
  />
)

export default Fonts
