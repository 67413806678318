import React from 'react'
import { useColorModeValue as mode, Box, Center, Heading, SimpleGrid } from "@chakra-ui/react"
import FeatureCard from 'components/common/FeatureCard'
import { withTranslation } from 'react-i18next'

import type { FCC } from 'types/utils'
import type { WithTranslation } from 'react-i18next'

const Features: FCC<WithTranslation> = ({t}) => {
  return (
    <Center>
      <Box maxWidth="960px" paddingX="16px" paddingY="64px">
        <Heading as="h1" textAlign="center" fontSize={['24px', '32px', '40px']} fontWeight="bold" mb="64px">
          {t('meet all your needs')}
        </Heading>
        <SimpleGrid columns={[1, 2, 3]} gap="56px">
          <FeatureCard
            image="/theme-tools/feature-1.png"
            title={t('add themes')}
            description={t('add themes description')}
            background={mode('blue.100', 'gray.900')}
          />
          <FeatureCard
            image="/theme-tools/feature-2.png"
            title={t('split by prefix')}
            description={t('split by prefix description')}
            background={mode('blue.100', 'gray.900')}
          />
          <FeatureCard
            image="/theme-tools/feature-3.png"
            title={t('kinds of styles')}
            description={t('kinds of styles description')}
            background={mode('blue.100', 'gray.900')}
          />
          <FeatureCard
            image="/theme-tools/feature-4.png"
            title={t('copy as another theme')}
            description={t('copy as another theme description')}
            background={mode('blue.100', 'gray.900')}
          />
          <FeatureCard
            image="/theme-tools/feature-5.png"
            title={t('share with teammates')}
            description={t('share with teammates description')}
            background={mode('blue.100', 'gray.900')}
          />
          <FeatureCard
            image="/theme-tools/feature-6.png"
            title={t('ignore marked')}
            description={t('ignore marked description')}
            background={mode('blue.100', 'gray.900')}
          />
        </SimpleGrid>
      </Box>
    </Center>
  )
}

export default withTranslation('themeTools')(Features)
